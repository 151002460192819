/* Colors Neuhaus */
/* Fonts Neuhaus */
.cover-image-tile figure {
  position: relative; }

.cover-image-tile .tile-text-content {
  padding-top: 1rem;
  height: 100%; }
  .cover-image-tile .tile-text-content div {
    height: auto; }
  @media (max-width: 768.98px) {
    .cover-image-tile .tile-text-content {
      border-bottom: 1px solid #ddd;
      padding-bottom: 1rem; } }
  @media (min-width: 769px) {
    .cover-image-tile .tile-text-content {
      padding: 2rem;
      position: absolute;
      top: 0;
      width: 65%; }
      .cover-image-tile .tile-text-content h3 {
        font-size: calc(1.325rem + 0.9vw); } }
    @media (min-width: 769px) and (min-width: 1200px) {
      .cover-image-tile .tile-text-content h3 {
        font-size: 2rem; } }
  @media (min-width: 1200px) {
    .cover-image-tile .tile-text-content {
      padding: 3rem; } }

@media (min-width: 769px) {
  .imagegrid-coverimagetile-big div,
  .imagegrid-coverimagetile-big figure,
  .imagegrid-coverimagetile-big img {
    height: 100%; } }
